import React from 'react'
import Cards from '../components/Cards/Cards'
import Nav from '../components/Nav/Nav'
import Footer from '../components/Footer/Footer'
import '../assets/scss/Base/mainPage.scss'
import images from '../assets/config/images'
const MainPage = () => {
    return (
        <div>
            <Nav />
            <div className='main'>
                <div className='main__tittle'>
                    <h1>Pozícióink</h1>
                    <img id='decoration' src={ images.decoration } alt="" srcset="" />
                </div>
                <div className='main__container'>
                    <Cards type='developer'/>
                    <div className='main__container__line'></div>
                    <Cards type='manager'/>
                </div>
            </div>
            <Footer />
        </div>
    )
    
}

export default MainPage