import React from 'react'
import './inputs.scss'
const Inputs = (props) => {
    if (props.type === 'dc') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="text" placeholder='pl.: gmpbence'/>
        )
    } else if (props.type === "call"){
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="text" placeholder='pl.: Bence'/>
        )
    }else if (props.type === 'email') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="email" placeholder='pl.: info@gmpbence.hu'/>
        )
    } else if (props.type === 'dev') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="text" placeholder='pl.: Java'/>
        )
    } else if (props.type === 'dev__keret') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="text" placeholder='pl.: React Js'/>
        )
    } else if (props.type === 'dev__projects') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="number" placeholder='pl.: 2' min={0} max={99}/>
        )
    } else if (props.type === 'cv') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="text" placeholder='pl.: Én egy 15 éves...'/>
        )
    } else if (props.type === 'age') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="number" placeholder='pl.: 15' min={14} max={100}/>
        )
    } else if (props.type === 'programming__since') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="number" placeholder='pl.: 6' min={1} max={50}/>
        )
    }  else if (props.type === 'pm_why') {
        return (
            <input onChange={(e) => props.onChange(e)} className='question__input' type="text" placeholder='pl.: Azért szeretnék...' />
        )
    }
}

export default Inputs