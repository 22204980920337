import React from 'react'
import './buttons.scss'
import Popups from '../Popups/Popups'

const Button = (props) => {

    if (props.type === 'submit') {
        return (
            <button onClick={props.onClick} className='button__apply'>Apply</button>
        )
    }
    if (props.type === 'cancel') {
        return (
            <button onClick={props.onClick} className='button__cancel'>Mégse</button>
        )
    }
    if (props.type === 'agree') {
        return (
            <div>
                <button onClick={props.onClick} className='button__agree'>Igen</button>
            </div>

        )
    }
    return (
        <div>Buttons</div>
    )
}

export default Button