import React from 'react'
import './popups.scss'
import Button from '../Buttons/Buttons'
import { getFormDataDev, getFormDataPM, sendDevDataToBackend, sendPMDataToBackend } from '../../functions/dataFunctions'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
const Popups = (props) => {

    const navigate = useNavigate()
    const onAgreeDev = async () => {
        props.displayPopup(false)
        const devData = getFormDataDev()
        const isSuccess = await sendDevDataToBackend(devData)
        if (isSuccess === "missing_data") {
            toast("Minden adat szükseges", {
                autoClose: 10000,
                theme: "colored",
                type: "error"
            });
            navigate("/")
        }else if (isSuccess === "already_applied") {
            toast("Te már korábban jelentkeztél", {
                autoClose: 10000,
                theme: "colored",
                type: "error"
            });
            navigate("/")
        } else if (isSuccess == "success") {
            toast("Sikeres Jelentkezés", {
                autoClose: 10000,
                theme: "colored",
                type: "success"
            });
            navigate("/")
        }
    }
    const onAgreePM = async () => {
        props.displayPopup(false)
        const pmData = getFormDataPM()
        const isSuccess = await sendPMDataToBackend(pmData)

        if (isSuccess === "missing_data") {
            toast("Minden adat szükseges", {
                autoClose: 10000,
                theme: "colored",
                type: "error"
            });
            navigate("/")
        }else if (isSuccess === "already_applied") {
            toast("Te már korábban jelentkeztél", {
                autoClose: 10000,
                theme: "colored",
                type: "error"
            });
            navigate("/")
        } else if (isSuccess == "success") {
            toast("Sikeres Jelentkezés", {
                autoClose: 10000,
                theme: "colored",
                type: "success"
            });
            navigate("/")
        }
    }
    
    if (props.popupDisplayed) {
        return (
            <div>
                <div  className='popup'>
                    <div className='popup__container'>
                        <div className='popup__container__item'>
                            <h1>Developer Jelentkezés</h1>
                        </div>
                        <div className='popup__container__item'>
                            <p>Biztos vagy benne, hogy mindent helyesen válaszoltál meg?</p>
                        </div>
                        <div className='popup__container__item'>
                            <Button onClick={onAgreeDev} type='agree'/>
                            <Button onClick={() => props.displayPopup(false)} type='cancel'/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    if (props.popupDisplayed2) {
        return (
            <div>
                <div  className='popup'>
                    <div className='popup__container'>
                        <div className='popup__container__item'>
                            <h1>Partner Manager Jelentkezés</h1>
                        </div>
                        <div className='popup__container__item'>
                            <p>Biztos vagy benne, hogy mindent helyesen válaszoltál meg?</p>
                        </div>
                        <div className='popup__container__item'>
                            <Button onClick={onAgreePM} type='agree'/>
                            <Button onClick={() => props.displayPopup(false)} type='cancel'/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div></div>
    )
}

export default Popups