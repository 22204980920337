import React from 'react'
import './footer.scss'
const Footer = () => {
    return (
        <div>
            <div className='footer'>
                <div className='footer__container'>
                    <h1>© Copyright 2024 WorkingShark Development. All rights reserved</h1>
                </div>
            </div>
        </div>
    )
}

export default Footer