let formDataDev = {
    dcName: '',
    callName: '',
    email: '',
    age: '',
    cv: '',
    experience: '',
    projects: '',
    frameworks: '',
    language: ''
};
let formDataPM = {
    dcName: '',
    callName: '',
    email: '',
    age: '',
    cv: '',
    text: ''
};

export function setFormDataDev(data) {
    formDataDev = { ...formDataDev, ...data };
}

export function getFormDataDev() {
    console.log(formDataDev)
    return formDataDev;
}
export function setFormDataPM(data) {
    console.log(data)
    formDataPM = { ...formDataPM, ...data };
}

export function getFormDataPM() {
    return formDataPM;
}

export async function sendDevDataToBackend(data) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    const raw = JSON.stringify({
        dcName: data.dcName,
        callName: data.callName,
        email: data.email,
        age: data.age,
        cv: data.cv,
        experience: data.experience,
        projects: data.projects,
        frameworks: data.frameworks,
        language: data.language
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
        mode: 'cors'
    };

    return fetch("https://tapi.workingshark.com/api/v1/apply/dev", requestOptions)
        .then((response) => {

            if (!response.ok) {
                if (response.status === 400) {
                    return { error: "required_data_missing" }; 
                } else if (response.status === 403){
                    return { error: "already_applied" }; 
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }

            return response.json();
        })
        .then((data) => {

            if (data.error === "already_applied") {
                return "already_applied";
            } else if (data.message === "success") {
                return "success";
            } else if(data.error === "required_data_missing") {
                return "missing_data";
            }
            return "unknown_response"; 
        })
        .catch((error) => {
            console.error('Fetch error:', error);
            return "fetch_error"; 
        });
}

export async function sendPMDataToBackend(data) {
    
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    const raw = JSON.stringify({
        dcName: data.dcName,
        callName: data.callName,
        email: data.email,
        age: data.age,
        cv: data.cv,
        text: data.text
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
        mode: 'cors'
    };

    return fetch("https://tapi.workingshark.com/api/v1/apply/pm", requestOptions)
    .then((response) => {

        if (!response.ok) {
            if (response.status === 400) {
                return { error: "required_data_missing" }; 
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        }

        return response.json();
    })
    .then((data) => {

        if (data.error === "already_applied") {
            return "already_applied";
        } else if (data.message === "success") {
            return "success";
        } else if(data.error === "required_data_missing") {
            return "missing_data";
        }
        return "unknown_response"; 
    })
    .catch((error) => {
        console.error('Fetch error:', error);
        return "fetch_error"; 
    });
}
