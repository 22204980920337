import React from 'react'
import './cards.scss'
import images from '../../assets/config/images'
import { useNavigate } from 'react-router-dom'
const Cards = (props) => {
    const navigate = useNavigate()

    const onClickDev = () => { 
        navigate('/apply/developer')
    }
    const onClickPm = () => { 
        navigate('/apply/partner-manager')
    }

    if (props.type === 'developer') {
        return (
            <div>
                <div onClick={onClickDev} className="card">
                    <div className="card__body"> 
                        <h1>Developer</h1>
                        <img src={ images.cards__developer } alt="" />
                    </div>
                </div>
            </div>
        )
    } else if (props.type === 'manager') {
        return (
            <div>
                <div onClick={onClickPm} className="card">
                    <div className="card__body"> 
                        <h1>Partner Manager</h1>
                        <img src={ images.cards__manager } alt="" />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>Cards</div>
        )
    }

}

export default Cards