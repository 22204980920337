import React from 'react'
import './nav.scss'
import images from '../../assets/config/images'
import { useNavigate } from 'react-router-dom'

const Nav = () => {
    const navigate = useNavigate()
    const onClickPM = () => {
        navigate('/apply/partner-manager')
    }
    const onClickWs = () => {
        navigate('/')
    }
    const onClickDev = () => {
        navigate('/apply/developer')
    }

    return (
        <div>
            <div className='nav'>
                <div className='nav__container'>
                    <div className='nav__container__item'>
                        <h1 onClick={onClickPM}>Partner Manager</h1>
                        <div className='nav__container__item__line'></div>
                    </div>

                    <div  className='nav__container__icon'>
                        <img src={ images.topicon } alt="topicon" />
                        <h2 onClick={onClickWs}>WorkingShark</h2>
                    </div>
                    <div className='nav__container__item'>
                        <h1 onClick={onClickDev}>Developer</h1>
                        <div className='nav__container__item__line__small'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nav