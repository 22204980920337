import React, { useState } from 'react'
import Nav from '../components/Nav/Nav'
import Footer from '../components/Footer/Footer'
import Inputs from '../components/Inputs/Inputs'
import images from '../assets/config/images'
import '../assets/scss/Base/apply.scss'
import Button from '../components/Buttons/Buttons'
import Popups from '../components/Popups/Popups'
import { setFormDataDev } from '../functions/dataFunctions'



const DevApplyPage = () => {

    const [popupDisplayed, displayPopup] = useState(false);

    const [dcName, setDcName] = useState('');
    const [callName, setCallName] = useState('');
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('');
    const [cv, setCv] = useState('');
    const [experience, setExperience] = useState('');
    const [projects, setProjects] = useState('');
    const [frameworks, setFrameworks] = useState('');
    const [language, setLanguage] = useState('');

    const handleSaveData = () => {
        displayPopup(true)
        const data = {
            dcName,
            callName,
            email,
            age,
            cv,
            experience,
            projects,
            frameworks,
            language
        };
        setFormDataDev(data);  
    };

  return (
    <div>
        <Nav />
        <div className='apply'>
        <Popups popupDisplayed={popupDisplayed} displayPopup={displayPopup} type='dev__apply'/>
            <div className='apply__tittle'>
                <h1>Developer</h1>
                <img src={ images.cards__developer } alt="" />
            </div>
            <div className='apply__container'>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Mi a discord neved?</h1>
                    <Inputs onChange={(e) => setDcName(e.target.value) } type='dc'/>
                </div>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Hogy hívhatunk téged?</h1>
                    <Inputs onChange={(e) => setCallName(e.target.value) } type='call'/>
                </div>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Mi az email-ed?</h1>
                    <Inputs onChange={(e) => setEmail(e.target.value) } type='email'/>
                </div>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Hány éves vagy?</h1>
                    <Inputs onChange={(e) => setAge(e.target.value) } type='age'/>
                </div>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Mesélj magadról pár mondatban.</h1>
                    <Inputs onChange={(e) => setCv(e.target.value) } type='cv'/>
                </div>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Hány éve programozol?</h1>
                    <Inputs onChange={(e) => setExperience(e.target.value) } type='programming__since'/>
                </div>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Milyen nyelveket ismersz?</h1>
                    <Inputs onChange={(e) => setLanguage(e.target.value) } type='dev'/>
                </div>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Milyen keretrendszereket ismersz?</h1>
                    <Inputs onChange={(e) => setFrameworks(e.target.value) } type='dev__keret'/>
                </div>
                <div className='apply__container__item'>
                    <h1 className='h1__doubleline'>Hány bemutatásra alkalmas projected van?</h1>
                    <Inputs onChange={(e) => setProjects(e.target.value) } type='dev__projects'/>
                </div>
                    <Button onClick={handleSaveData} type='submit'/>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default DevApplyPage