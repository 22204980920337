import React, { useState } from 'react'
import Inputs from '../components/Inputs/Inputs'
import Popups from '../components/Popups/Popups'
import Nav from '../components/Nav/Nav'
import Button from '../components/Buttons/Buttons'
import Footer from '../components/Footer/Footer'
import '../assets/scss/Base/apply.scss'
import images from '../assets/config/images'
import { setFormDataPM } from '../functions/dataFunctions'

const PMApplyPage = () => {

    const [popupDisplayed2, displayPopup2] = useState(false);

    const [dcName, setDcName] = useState('');
    const [callName, setCallName] = useState('');
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('');
    const [cv, setCv] = useState('');
    const [text, setText] = useState('');

    const handleSaveData = () => {
        displayPopup2(true)
        const data = {
            dcName,
            callName,
            email,
            age,
            cv,
            text
        };
        setFormDataPM(data);
    }
  return (
    <div>
    <Nav />
    <div className='apply'>
    <Popups popupDisplayed2={popupDisplayed2} displayPopup={displayPopup2} type='dev__apply'/>
        <div className='apply__tittle'>
            <h1 className='pm__logo__text'>Partner Manager</h1>
            <img className='pm__logo' src={ images.cards__manager } alt="" />
        </div>
        <div className='apply__container'>
            <div className='apply__container__item'>
                <h1 className='h1__doubleline'>Mi a discord neved?</h1>
                <Inputs onChange={(e) => setDcName(e.target.value) } type='dc'/>
            </div>
            <div className='apply__container__item'>
                <h1 className='h1__doubleline'>Hogy hívhatunk téged?</h1>
                <Inputs onChange={(e) => setCallName(e.target.value) } type='call'/>
            </div>
            <div className='apply__container__item'>
                <h1 className='h1__doubleline'>Mi az email-ed?</h1>
                <Inputs onChange={(e) => setEmail(e.target.value) } type='email'/>
            </div>
            <div className='apply__container__item'>
                <h1 className='h1__doubleline'>Hány éves vagy?</h1>
                <Inputs onChange={(e) => setAge(e.target.value) } type='age'/>
            </div>
            <div className='apply__container__item'>
                <h1 className='h1__doubleline'>Mesélj magadról pár mondatban.</h1>
                <Inputs onChange={(e) => setCv(e.target.value) } type='cv'/>
            </div>
            <div className='apply__container__item'>
                <h1 className='h1__doubleline'>Miert szeretnel nalunk partner manager lenni?</h1>
                <Inputs onChange={(e) => setText(e.target.value) } type='pm_why'/>
            </div>
                <Button onClick={handleSaveData} type='submit'/>
        </div>
    </div>

    <Footer />
</div>
  )
}

export default PMApplyPage