import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import './assets/scss/App.scss'
import MainPage from './pages/MainPage';
import DevApplyPage from './pages/DevApplyPage';
import { SuccessApplyPage } from './pages/SuccessApplyPage';
import PMApplyPage from './pages/PMApplyPage';
import 'react-toastify/dist/ReactToastify.css';


function App() {
	return (
		<BrowserRouter >
			<Routes>
				<Route path= '/'  element= { <MainPage ></MainPage> }/>	
				<Route path= '/apply/success'  element= { <SuccessApplyPage /> }/>	
				<Route path= '/apply/developer'  element= { <DevApplyPage ></DevApplyPage> }/>
				<Route path= '/apply/partner-manager'  element= { <PMApplyPage /> }/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
